<!-- eslint-disable no-console -->
<template>
  <div
    ref="plpContainer"
    class="flex flex-col py-10"
    :class="{ 'pr-4': isLandingPage }"
  >
    <div v-if="!hideFilters" class="self-end">
      <ZenniZnFindYourSizeModal>
        <template #trigger>
          <button
            class="items-center gap-2"
            :class="isLandingPage ? 'hidden lg:flex' : 'flex'"
          >
            <span
              class="text-grays-darkest font-semibold underline underline-offset-2"
              >Find your size</span
            >
            <ZenniIconsIconRuler size="sm" />
          </button>
        </template>
      </ZenniZnFindYourSizeModal>
    </div>
    <div
      v-if="!hideFilters"
      class="border-grays-light mt-4 border-b"
      :class="{ 'hidden border-none lg:flex': isLandingPage }"
    ></div>
    <div class="mt-4 flex gap-6">
      <Transition
        enter-active-class="transition duration-150 ease-out"
        enter-from-class="transform -translate-x-full"
        enter-to-class="transform"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform"
        leave-to-class="transform -translate-x-full"
      >
        <div
          v-if="showFilter && windowWidth >= 700"
          class="w-[306px]"
          :class="isLandingPage ? 'hidden lg:flex' : ''"
        >
          <ZenniZnFilter
            v-model:selected-filters="selectedFilters"
            :filters="computedInitialFilters"
            :grouped="groupedFiltersFacetsName"
            :free-glasses="freeGlassesFacetsName"
          />
        </div>
      </Transition>
      <div
        v-if="showFilter"
        class="bg-grays-light hidden w-[1px] sm:block"
      ></div>
      <div class="flex flex-1 flex-col gap-6">
        <div
          v-if="!hideFilters"
          class="items-center gap-4"
          :class="
            isLandingPage
              ? 'hidden lg:flex'
              : 'border-grays-light flex border-b pb-4 md:border-none md:pb-0'
          "
        >
          <span class="text-grays-darkest flex-1 text-lg leading-[38px]"
            ><b>{{ totalItems }}</b> results</span
          >
          <button
            class="text-grays-darkest flex items-center gap-[11px] rounded-lg border border-[#808080] bg-white px-2.5 py-[9px] font-semibold leading-5"
            @click="() => (showFilter = !showFilter)"
          >
            <span>{{ showFilter ? 'Hide' : 'Show' }} filters</span>
            <img src="/img/icons/filter.svg" class="w-5" />
          </button>
          <ZnDropdown class="!z-0">
            <template #buttonText="{ open }">
              <div
                aria-role="button"
                class="text-grays-darkest flex items-center gap-[11px] rounded-lg border border-[#808080] bg-white px-2.5 py-[9px] font-semibold leading-5"
              >
                <span>Sort by</span>
                <ZenniIconsIconChevronDown
                  size="sm"
                  class="transition-transform duration-300 ease-in-out"
                  :class="{ 'rotate-180': open }"
                />
              </div>
            </template>
            <template #content>
              <div class="flex min-w-[240px] flex-col">
                <ZenniZnSelectList
                  v-model="sortIndex"
                  :options="sortOptions"
                  @update:model-value="sortSearch"
                />
              </div>
            </template>
          </ZnDropdown>
        </div>
        <ZenniZnFilterChipSlider
          v-if="chipValues.length > 0 && !hideFilters"
          :chip-values="chipValues"
          @remove="
            (e) =>
              selectedFilters[e.category as keyof SelectedFilters]?.splice(
                selectedFilters[e.category as keyof SelectedFilters]?.indexOf(
                  e.value as any,
                ) ?? -1,
                1,
              )
          "
          @remove-all="clearFilters"
        />
        <div
          v-if="totalItems === 0 && !loadingResult && !loading"
          class="text-grays-darkest flex flex-1 items-center justify-center"
        >
          <div class="flex flex-col gap-4">
            <h2 class="text-center text-2xl font-semibold xl:text-left">
              No results were found for
              {{ query ? `"${query}"` : 'your search' }}
            </h2>
            <h4 class="text-xl font-semibold">Search tips</h4>
            <ul class="list-disc pl-6">
              <li>Double check your spelling</li>
              <li>Try searching by product type or model number</li>
            </ul>
          </div>
        </div>
        <div
          class="place-items-start"
          :class="[
            showFilter
              ? 'grid-cols-1 lg:grid-cols-2'
              : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
            isLandingPage
              ? 'max-w-scrollable-section flex w-full flex-1 gap-x-12 gap-y-6 overflow-scroll pb-4 pr-4 lg:grid lg:max-w-7xl lg:gap-x-12 lg:overflow-auto lg:pb-0 lg:pr-0'
              : 'grid gap-x-12 gap-y-6',
          ]"
        >
          <ZenniZnProductCard
            v-for="product in computedHits"
            :id="product.id"
            :key="product.id"
            class="mx-auto w-full"
            v-bind="product"
            :vto-skus="vtoAvailableFrameSkus"
            :out-of-stock-skus="stockInformations"
            :loading="loadingResult || loading"
            :available-credit="availableCredit"
            :is-best-selling="product.isBestSelling"
            @go-to-product="goToProduct"
            @try-on-clicked="handleTryOnClick"
          />
        </div>
        <ZenniZnPagination
          v-if="totalItems > 0 && !loadingResult && !loading && !hideFilters"
          :class="isLandingPage ? 'hidden lg:flex' : ''"
          :total-items="totalItems"
          :current-page="page"
          :page-size="pageSize"
          class="mt-4"
          @update:current-page="changePage"
        />
      </div>
    </div>

    <ZenniZnFilterModal
      v-model:selected-filters="selectedFilters"
      :is-open="windowWidth < 700 && showFilter"
      :filters="computedInitialFilters"
      :grouped="groupedFiltersFacetsName"
      :free-glasses="freeGlassesFacetsName"
      @close="showFilter = false"
    />

    <ClientOnly>
      <FittingboxModal />
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useWindowSize } from '@vueuse/core'
import { useFittingbox } from '~/composables/useFittingbox'
import { useFilters } from '~/composables/useFilters'
import { useProducts } from '~/composables/useProducts'
import { useStockInformation } from '~/composables/useStockInformation'
import { useEmployeeAlgolia } from '~/composables/useEmployeeAlgolia'
import {
  ref,
  useGetAlgoliaSearchIndex,
  useRouter,
  useRoute,
  computed,
  watch,
  onMounted,
  onBeforeMount,
  useNuxtApp,
} from '#imports'
import { KIDS_GENDER_GROUP_STRING } from '~/data/categories'
import { useGuestStore } from '~/store/guest'
import { BUDGET_FILTERS } from '~/data/budget-filters'
import type {
  SelectedFilters,
  ZnFilterProps,
  Filters,
  ZnFilterChipValue,
} from './filter-types'
import type { ZnAlgoliaProductVariant } from './algolia-types'
import type { ZnPlpProps, ZnPlpEmits } from './types'

const { width: windowWidth } = useWindowSize()
const plpContainer = ref()

const props = withDefaults(defineProps<ZnPlpProps>(), {
  isLandingPage: false,
})
const emit = defineEmits<ZnPlpEmits>()

const showFilter = ref(false)

const { getAvailableFrames } = useFittingbox()

const algoliaIndex = useGetAlgoliaSearchIndex()

const {
  mapFilters,
  processMapFilters,
  mapHitToProductCard,
  groupedFiltersFacetsName,
  freeGlassesFacetsName,
} = useFilters()

const scrollToTop = () => {
  if (process.client && !props.isLandingPage) {
    plpContainer.value?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
  }
}

const router = useRouter()

const initialPage =
  router.currentRoute.value?.query?.page &&
  +router.currentRoute.value?.query?.page > 1
    ? +router.currentRoute.value?.query?.page
    : 1

const {
  getProducts,
  facets,
  totalItems,
  page,
  pageSize,
  hits,
  setPage,
  loading,
  mapFacets,
} = useProducts<ZnAlgoliaProductVariant>({
  hitsPerPage: props.limit ?? 12,
  page: initialPage - 1,
  indexName: algoliaIndex,
})

watch(totalItems, (newTotalItems) => {
  emit('update:totalItems', newTotalItems)
})

const query = ref('')
const loadingResult = ref(true)
const allowedFilters = ['Inventory_Level_search']

const route = useRoute()

const sortOptions = [
  {
    slug: 'price_asc',
    label: 'Price: Low to high',
    value: `${algoliaIndex}_price_asc`,
  },
  {
    slug: 'price_desc',
    label: 'Price: High to low',
    value: `${algoliaIndex}_price_desc`,
  },
]

const selectedFilters = ref<SelectedFilters>(props.initialFilter ?? {})
const initialFilters = ref<ZnFilterProps['filters']>()
const sortIndex = ref('')

const computedInitialFilters = computed<ZnFilterProps['filters']>(() => {
  if (!initialFilters.value) {
    return {}
  }
  const filters = JSON.parse(JSON.stringify(initialFilters.value))
  return processMapFilters(filters)
})

const chipValues = computed<ZnFilterChipValue[]>(() => {
  const arr: ZnFilterChipValue[] = []

  const selectedFiltersKeys = Object.keys(selectedFilters.value)

  selectedFiltersKeys.forEach((key) => {
    selectedFilters.value[key as Filters]?.forEach((value) => {
      arr.push({
        label:
          (value === 'nonprescription'
            ? 'Non-Prescription'
            : value === 'prescription'
            ? 'Prescription'
            : value === 'progressive'
            ? 'Progressives'
            : value === KIDS_GENDER_GROUP_STRING
            ? 'Kids'
            : '') ||
          computedInitialFilters.value![
            key as keyof SelectedFilters
          ]?.facet?.values.find(
            (e: any) => e.value.toLowerCase() === value.toLocaleLowerCase(),
          )?.label ||
          (key.toLowerCase().includes('price') ? `$${value}` : ''),
        value,
        category: key,
      })
    })
  })
  return arr
})

const clearFilters = () => {
  const keys = Object.keys(selectedFilters.value)
  keys.forEach((key) => {
    delete selectedFilters.value[key as keyof SelectedFilters]
  })
  // sortIndex.value = '';
}

const computedHits = computed<any[]>(() => {
  // the next line is to simulate product while loading and show skeletons
  if (loadingResult.value || loading.value) {
    return Array.from({ length: pageSize.value }, (_, i) => ({ i }))
  }
  const frames = hits.value.map((hit: ZnAlgoliaProductVariant) =>
    mapHitToProductCard(
      hit,
      router.currentRoute.value?.query?.activeImage === 'tinted',
    ),
  )

  // Sorting to put out of stock frames to the end
  return frames.sort((a, b) => {
    if (
      stockInformations.value?.[a.variants?.[0]?.sku || ''] &&
      !stockInformations.value?.[b.variants?.[0]?.sku || '']
    ) {
      return 1
    }
    if (
      !stockInformations.value?.[a.variants?.[0]?.sku || ''] &&
      stockInformations.value?.[b.variants?.[0]?.sku || '']
    ) {
      return -1
    }
    return 0
  })
})

const changePage = (page: number) => {
  setPage(page - 1, sortIndex.value)
  scrollToTop()
}

const updateRouteParams = () => {
  if (props.isLandingPage) {
    return
  }
  const params: SelectedFilters & {
    sort?: string
    query?: string
    page?: string
  } = {
    ...selectedFilters.value,
  }
  if (sortIndex.value) {
    params.sort = getSearchSlug(sortIndex.value)
  }
  if (query.value) {
    params.query = query.value
  }
  if (page.value > 1) {
    params.page = page.value.toString()
  }
  router.push({
    query: {
      ...(router.currentRoute.value?.query || {}),
      ...params,
    },
  })
}

const normalizeFilters = (filters: Record<string, string[]>) => {
  if ((filters.Frame___Prescription_types_search?.length ?? 0) > 0)
    if (filters.Frame___Prescription_types_search.length === 2) {
      return {
        ...filters,
        Frame___Prescription_types_search: [],
      }
    } else if (
      filters.Frame___Prescription_types_search[0] === 'prescription'
    ) {
      return {
        ...filters,
        Frame___Prescription_types_search: [
          'bifocal',
          'progressive',
          'singlevision',
          'reader',
        ],
      }
    }
  return filters
}

const search = async (
  querySearch?: string,
  selectedFiltersSearch?: Record<string, string[]>,
  sortBy?: string,
) => {
  let filters = {
    ...(selectedFiltersSearch || selectedFilters.value),
    family: ['frame'],
    objectType: ['product-variant'],
    // enabled: [true],
  }
  if (props.productCategories) {
    filters = {
      ...filters,
      categories: props.productCategories,
    }
  }
  const normalizedFilters = normalizeFilters(filters)
  await getProducts({
    query: querySearch || query.value,
    filters: normalizedFilters,
    sortBy: sortBy || sortIndex.value,
  })
  if (!initialFilters.value) {
    initialFilters.value = { ...facets.value } as any
  }
  // Next function updates the initial filter facets with the current product count
  // There was a task to NOT DO this ald keeps all initial counts.
  // if this changes, uncomment the line below
  // updateInitialFilterFacets();
}

const getSearchSlug = (sort: string) => {
  return sortOptions.find((option) => option.value === sort)?.slug
}

const sortSearch = async (sort: string) => {
  sortIndex.value = sort
  loadingResult.value = true
  await search()
  loadingResult.value = false
  scrollToTop()
  updateRouteParams()
}

const updateInitialFilterFacets = () => {
  if (!facets.value) return
  Object.keys(facets.value).forEach((key) => {
    const mapFilterItem = (mapFilters as any)[key as Filters]
    const initialFilterItem = initialFilters.value![key as Filters]
    const facetItem = facets.value![key as Filters]
    if (mapFilterItem && initialFilterItem?.values && facetItem?.values) {
      initialFilterItem.values = initialFilterItem.values.map((val: any) => ({
        ...val,
        productCount:
          facetItem.values.find(
            (e: any) => e.value.toLowerCase() === val.value.toLowerCase(),
          )?.productCount || 0,
      })) as any
    }
  })
}

watch(
  () => selectedFilters.value,
  async () => {
    loadingResult.value = true
    await search(query.value, selectedFilters.value)
    loadingResult.value = false
    scrollToTop()
    updateRouteParams()
  },
  { deep: true },
)

watch(
  () => page.value,
  () => updateRouteParams(),
  { deep: true },
)

const goToProduct = ({ sku, objectId }: { sku: string; objectId?: string }) => {
  // store last category for back button
  router.push({
    path: `/products/${sku}`,
    query: {
      gift: route.query.gift,
      objectId,
      activeImage: route.query?.activeImage,
    },
  })
}

const reloadQueryParamsAndSearch = async () => {
  const params = JSON.parse(JSON.stringify(route.query))
  const paramsKeys = Object.keys(params)
  if (paramsKeys.length > 0) {
    paramsKeys.forEach((key) => {
      if (key === 'sort') {
        sortIndex.value =
          sortOptions.find((option) => option.slug === (params[key] as string))
            ?.value ?? ''
      }
      if (
        Object.keys(mapFilters).includes(key) ||
        allowedFilters.includes(key)
      ) {
        selectedFilters.value[key as Filters] =
          typeof params[key] === 'string'
            ? [params[key] as string]
            : (params[key] as string[])
      }
      if (key === 'query') {
        query.value = params[key] as string
      }
    })
  }
  await search()
}

watch(
  () => route.query,
  async () => {
    await reloadQueryParamsAndSearch()
  },
  { deep: true },
)

onBeforeMount(() => {
  const guestStore = useGuestStore()
  const { currentGift } = storeToRefs(guestStore)
  const creditLeft = currentGift.value
    ? guestStore.calculateGiftAmt(currentGift.value)
    : 0

  if (!props.isLandingPage && creditLeft >= 25) {
    const selectedFilter = BUDGET_FILTERS.sort(
      (a, b) => b.price - a.price,
    ).find((filter) => filter.price <= creditLeft)

    selectedFilters.value = {
      ...(selectedFilters.value as any),
      ...(selectedFilter?.initialFilter as any),
    }
  }
})

onMounted(async () => {
  // windowWidth is only available on client side
  showFilter.value = windowWidth.value >= 700 && !props.isLandingPage
  await reloadQueryParamsAndSearch()
  loadingResult.value = false

  //   const listName = breadcrumbs.value.at(-1)?.name || 'Product list page'
  //   const products = hits.value.map((e) => Object.values(e.products)).flat()
  //   viewPLPTrackEvent(listName, products)
})
const { result: stockInformations, getStockInformation } = useStockInformation()

const vtoAvailableFrameSkus = ref<string[]>([])

watch(
  () => hits.value,
  () => {
    getAvailableFrames(
      hits.value.flatMap((variant) =>
        Object.values(variant.products).map((p) => p.sku),
      ),
    ).then((res: string[]) => (vtoAvailableFrameSkus.value = res))

    getStockInformation({
      skus: hits.value.flatMap((variant) =>
        Object.values(variant.products).map((p) => p.sku),
      ),
    })
  },
  { deep: true },
)

const { $fittingboxContext } = useNuxtApp()
const handleTryOnClick = (sku: string) => {
  if (sku) {
    $fittingboxContext.selectFrame(sku)
  }
}

// SSR
// this search is for getting all initial available facets and its product count

const { search: initialSearch, result: initialResults } = useEmployeeAlgolia()
let filters = 'objectType:"product-variant"'
props.productCategories?.forEach((category) => {
  filters += ` AND categories:${category}`
})
await initialSearch({
  query: '',
  requestOptions: {
    facets: ['*'],
    // facetFilters: `enabled: true`, // only get enabled products
    filters,
  },
}) // first complete search to get all available filters
if (initialResults.value?.facets) {
  initialFilters.value = mapFacets(
    initialResults.value.facets,
  ) as ZnFilterProps['filters']

  // Remove initial filter values with no products from selectedFilters
  if (selectedFilters.value) {
    Object.keys(selectedFilters.value).forEach((filterKey) => {
      const facet = initialFilters.value?.[filterKey as Filters]
      if (facet?.facet?.values) {
        selectedFilters.value[filterKey as Filters] = selectedFilters.value[
          filterKey as Filters
        ]?.filter((selectedValue) => {
          if (selectedValue === KIDS_GENDER_GROUP_STRING) {
            return true
          }
          return facet.facet.values.some(
            (value: any) =>
              value.value === selectedValue && value.productCount > 0,
          )
        })

        // Remove the filter key if no values remain
        if (selectedFilters.value[filterKey as Filters]?.length === 0) {
          delete selectedFilters.value[filterKey as Filters]
        }
      }
    })
  }

  updateInitialFilterFacets()
}
</script>
