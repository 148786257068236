export interface CategoryMetadata {
  title: string
  href: string
  initialFilter: Record<string, string[]>
}

export const KIDS_GENDER_GROUP_STRING = '[girl_s,boy_s,unisex_kids]'
export const CATEGORIES_METADATA: CategoryMetadata[] = [
  {
    title: "Women's Glasses",
    href: '/products/list/women',
    initialFilter: {
      Frame___Gender_search: ['women_s', 'Women_s', 'Unisex', 'unisex'],
    },
  },
  {
    title: "Men's Glasses",
    href: '/products/list/men',
    initialFilter: {
      Frame___Gender_search: ['men_s', 'Men_s', 'Unisex', 'unisex'],
    },
  },
  {
    title: 'Unisex Glasses',
    href: '/products/list/unisex',
    initialFilter: {
      Frame___Gender_search: ['unisex'],
    },
  },
  {
    title: 'Non-Prescription Glasses',
    href: '/products/list/non-rx',
    initialFilter: {
      Frame___Prescription_types_search: ['nonprescription'],
    },
  },
  {
    title: 'Prescription Glasses',
    href: '/products/list/prescription',
    initialFilter: {
      Frame___Prescription_types_search: ['prescription'],
    },
  },
  {
    title: "Kids' Eyeglasses",
    href: '/products/list/kids',
    initialFilter: {
      Frame___Gender_search: [KIDS_GENDER_GROUP_STRING],
    },
  },
  {
    title: 'Sunglasses',
    href: '/products/list/sunglasses',
    initialFilter: {
      Frame___Gender_search: [
        'unisex',
        'Unisex',
        'women_s',
        'Women_s',
        'men_s',
        'Men_s',
      ],
    },
  },
  {
    title: "Women's Sunglasses",
    href: '/products/list/women-sunglasses',
    initialFilter: {
      Frame___Gender_search: ['women_s', 'Women_s'],
    },
  },
  {
    title: "Men's Sunglasses",
    href: '/products/list/men-sunglasses',
    initialFilter: {
      Frame___Gender_search: ['men_s', 'Men_s'],
    },
  },
  {
    title: "Kids' Sunglasses",
    href: '/products/list/kids-sunglasses',
    initialFilter: {
      Frame___Gender_search: [KIDS_GENDER_GROUP_STRING],
    },
  },
  {
    title: 'Bestselling Eyeglasses',
    href: '/products/list/bestselling',
    initialFilter: {
      Badges_search: ['bestselling'],
      Frame___Gender_search: [
        'unisex',
        'Unisex',
        'women_s',
        'Women_s',
        'men_s',
        'Men_s',
      ],
    },
  },
  {
    title: 'Bestselling Sunglasses',
    href: '/products/list/bestselling-sunglasses',
    initialFilter: {
      Badges_search: ['bestselling'],
      Frame___Gender_search: [
        'unisex',
        'Unisex',
        'women_s',
        'Women_s',
        'men_s',
        'Men_s',
      ],
    },
  },
  {
    title: 'Progressives',
    href: '/products/list/progressive',
    initialFilter: {
      Frame___Prescription_types_search: ['progressive'],
    },
  },
  {
    title: 'Free with Credit',
    href: '/products/list/free-with-credit',
    initialFilter: {
      Is_Under_150_Limit: ['true'],
    },
  },
]
